(function () {
  'use strict';

  angular
    .module('neo.home.statistics.licenses')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.statistics.licenses', {
        url: '/licenses',
        templateUrl: 'home/statistics/licenses/licenses.tpl.html',   
        data: {
          townHall: undefined
        },
        controller: 'StatsLicensesCtrl',
        controllerAs: 'vm',
        resolve: {
          licenceStats: function (LicenceStatistics, currentSeason) {
            return LicenceStatistics.query({season_id: currentSeason.id}).$promise;
          }, townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          }
        }
      });
  }
}());
